.vh{
    transition: .4s;
    font-size: 16px !important;
}

.vh:hover{
    color: rgb(188, 248, 215) !important;
}

.sh{
    transition: .4s;
}

.sh:hover{
    color: rgb(188, 248, 215) !important;
}

.evidence{
    color: rgb(19, 128, 68) !important;
}

.evidence_a{
    color: rgb(19, 128, 68) !important;
    transition: .4s;
    text-decoration: none;
}

.evidence_a:hover{
    color: rgb(54, 187, 113) !important;
}

.btn_evidence{
    background-color: rgb(19, 128, 68) !important;
    transition: .4s;
}

.btn_evidence:hover{
    background-color: rgb(31, 154, 86) !important;
}

.custom_img {
    max-width: 100% !important;
    height: 500px !important;
    width: 1920px !important;
    background-size: cover !important;
}

html {
    position: relative;
    min-height: 100%;
}
body {
    margin-bottom: 350px !important;
}
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

@media (max-width: 768px) {
    body {
        padding-bottom: 350px; /* Riduci il padding inferiore per dispositivi più piccoli */
    }
}

.cursor-p{
    cursor: pointer;
}

.version_api{
    text-decoration: none !important;
    /*font-weight: bold !important;*/
    font-weight: 700 !important;
}

.text-outline {
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: 
        1px 1px 0px rgba(139, 0, 0, 0.5),     /* Rosso scuro */
        -1px -1px 0px rgba(139, 0, 0, 0.5),    /* Rosso scuro */
        1px -1px 0px rgba(139, 0, 0, 0.5),     /* Rosso scuro */
        -1px 1px 0px rgba(139, 0, 0, 0.5);     /* Rosso scuro */
}

